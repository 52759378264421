import React, { useState } from "react";
import "./pageContact.css";

const FORM_ENDPOINT = "https://formsubmit.co/kontakt@cookielookiemedia.pl";

function useContactForm() {
  const [status, setStatus] = useState<{ code: number; message: string }>();

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const injectedData: Record<string, string> = {
      // Here you can specify anything you need to inject dynamically, outside the form. For example:
      // DYNAMIC_DATA_EXAMPLE: 123,
    };

    const inputs = Array.from(e.currentTarget.elements) as HTMLFormElement[];
    const data = inputs
    .filter((input) => input.name)
    .reduce(
      (obj, input) => Object.assign(obj, { [input.name]: input.value }),
      {} as Record<string, string>
    );

  Object.assign(data, injectedData);

  fetch(FORM_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

      .then((response) => {
        // It's likely a spam/bot submission, so bypass it to validate via captcha challenge old-school style
        if (response.status === 422) {
          // Append dynamically generated keys back to the form
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.currentTarget.appendChild(el);
          });

          // Let's submit the form again and spammer/bot will be redirected to another page automatically
          // Submitting via javascript will bypass calling this function again
          e.currentTarget.submit();

          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response;
      })
      .then(() => setStatus({ code: 1, message: "Skontaktujemy się wkrótce!" }))
      .catch((err) => setStatus({ code: 0, message: err.toString() }));
  };

  return { status, handleFormSubmit };
}

function ContactForm() {
  const { status, handleFormSubmit } = useContactForm();

  if (status && status.code === 1) {
    return (
      <div className="">
        <div className="">
          <div className="">Dziękuję za kontakt!</div>

          <div className="">{status.message}</div>
        </div>
      </div>
    );
  }

  if (status && status.code === 0) {
    return (
      <div className="">
        <div className="">
          <div className="">Mamy tymczasowy problem z formularzem!</div>

          <div className="">{status.message}</div>
          <div>
            <p>Proszę o kontakt telefoniczny: +48 697 183 186</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="FormContainer">
      <h1>Kontakt</h1>
      <form method="POST" action={FORM_ENDPOINT} onSubmit={handleFormSubmit}>
        <div className="form-row">
          <div className="input-data">
            <input
              type="text"
              id="name"
              name="name"
              autoComplete="off"
              required
            />
            <div className="underline"></div>
            <label htmlFor="name">Imię </label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data">
            <input
              type="text"
              id="email"
              name="email"
              autoComplete="off"
              required
            />
            <div className="underline"></div>
            <label htmlFor="email">Adres email</label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data textarea">
            <textarea
              id="message"
              name="message"
              rows={6}
              autoComplete="off"
              required
            ></textarea>
            <br />
            <div className="underline"></div>
            <label htmlFor="message">Wiadomość</label>
          </div>
        </div>
        <div className="form-row submit-btn">
          <div className="input-data">
            <div className="inner"></div>
            <input type="submit" value="wyślij" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
