import React, { useEffect, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import "./mainLayout.css";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { AppRoutes } from "../../router";
import { DataContext } from "../../App";
import logo from "./../../assets/piotrek_logo.png";
import { SanityConfig } from "../../sanity";

import "vanilla-cookieconsent/dist/cookieconsent.css";
//import * as CookieConsent from "vanilla-cookieconsent";

function MainLayout() {
  const location = useLocation();

  // useEffect(() => {
  //   CookieConsent.run({

  //   cookie: {
  //     name: 'cc_cookie',
  // },

  // // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  // guiOptions: {
  //     consentModal: {
  //         layout: 'cloud inline',
  //         position: 'bottom center',
  //         equalWeightButtons: true,
  //         flipButtons: false
  //     },
  //     preferencesModal: {
  //         layout: 'box',
  //         equalWeightButtons: true,
  //         flipButtons: false
  //     }
  // },

  // onFirstConsent: ({cookie}) => {
  //     console.log('onFirstConsent fired',cookie);
  // },

  // onConsent: ({cookie}) => {
  //     console.log('onConsent fired!', cookie)
  // },

  // onChange: ({changedCategories, changedServices}) => {
  //     console.log('onChange fired!', changedCategories, changedServices);
  // },

  // categories: {
  //     necessary: {
  //         enabled: true,  // this category is enabled by default
  //         readOnly: true  // this category cannot be disabled
  //     },
  //     analytics: {
  //         autoClear: {
  //             cookies: [
  //                 {
  //                     name: /^_ga/,   // regex: match all cookies starting with '_ga'
  //                 },
  //                 {
  //                     name: '_gid',   // string: exact cookie name
  //                 }
  //             ]
  //         },

  //         services: {
  //             ga: {
  //                 label: 'Google Analytics',
  //                 onAccept: () => {},
  //                 onReject: () => {}
  //             },
  //         }
  //     },
  // },

  // language: {
  //     default: 'pl',
  //     translations: {
  //         pl: {
  //             consentModal: {
  //                 title: 'Mamy ciasteczka!',
  //                 description: 'Pomagają nam zrozumieć ruch na stronie i wyświetlać filmiki',
  //                 acceptAllBtn: 'Zaakceptuj wszystkie',
  //                 acceptNecessaryBtn: 'Odrzuć wrzystkie',
  //                 // closeIconLabel: 'Reject all and close modal',
  //                 footer: `
  //                 `,
  //             },
  //             preferencesModal: {
  //                 title: 'Manage cookie preferences',
  //                 acceptAllBtn: 'Accept all',
  //                 acceptNecessaryBtn: 'Reject all',
  //                 savePreferencesBtn: 'Accept current selection',
  //                 closeIconLabel: 'Close modal',
  //                 serviceCounterLabel: 'Service|Services',
  //                 sections: [
  //                     {
  //                         title: 'Your Privacy Choices',
  //                         description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
  //                     },
  //                     {
  //                         title: 'Strictly Necessary',
  //                         description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

  //                         //this field will generate a toggle linked to the 'necessary' category
  //                         linkedCategory: 'necessary'
  //                     },
  //                     {
  //                         title: 'Performance and Analytics',
  //                         description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
  //                         linkedCategory: 'analytics',
  //                         cookieTable: {
  //                             caption: 'Cookie table',
  //                             headers: {
  //                                 name: 'Cookie',
  //                                 domain: 'Domain',
  //                                 desc: 'Description'
  //                             },
  //                             body: [
  //                                 {
  //                                     name: '_ga',
  //                                     domain: 'cookielookiemedia.com',
  //                                     desc: 'Description 1',
  //                                 },
  //                                 {
  //                                     name: '_gid',
  //                                     domain: 'cookielookiemedia.com',
  //                                     desc: 'Description 2',
  //                                 }
  //                             ]
  //                         }
  //                     },
  //                 ]
  //             }
  //         }
  //     }
  // }
  //   });
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  const currentOutlet = useOutlet();
  const { nodeRef } =
    AppRoutes.find((route) => route.path === location.pathname) ?? {};

  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [customerLogos, setCustomerLogos] = useState([]);
  const [aboutMe, setAboutMe] = useState({
    url: "",
    description: [],
    title: "",
  });
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState({
    textSection: [],
    copyright: "Copyright © 2023 CookieLookie Media. All Rights Reserved",
    video: "",
  });

  const fetchData = async () => {
    const client = SanityConfig;
    try {
      setLoading(true);

      const query =
        '{ "youtubeVideos": *[_type == "portfolioItem"]|order(orderRank), "aboutMe": *[_type == "aboutMeItem"], "footer": *[_type == "footerItem"]{textSection, copyright, "video": videoText.asset->url,}, "customerLogos": *[_type == "customerItem"] }';
      const data = await client.fetch(query);
      setYoutubeVideos(data.youtubeVideos);
      setCustomerLogos(data.customerLogos);
      setAboutMe(data.aboutMe[0]);
      setFooter(data.footer[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("fetching page data");
    fetchData();
  }, []);

  return (
    <DataContext.Provider
      value={{
        youtubeVideos: youtubeVideos,
        loading: loading,
        aboutMe: aboutMe,
        customerLogos: customerLogos,
        footer: footer,
      }}
    >
      {
        <div className={loading ? "showing" : "showing hidden"}>
          <div className="logoLoader">
            <img alt="Logo firmy cookielookie" src={logo} />
          </div>
        </div>
      }
      <div className="App">
        <Header></Header>
        <div className="AboutPage">
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              nodeRef={nodeRef}
              timeout={200}
              classNames="page"
              unmountOnExit
            >
              {(state) => (
                <div ref={nodeRef} className="AboutPage">
                  {currentOutlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
        <Footer></Footer>
      </div>
    </DataContext.Provider>
  );
}

export default MainLayout;
